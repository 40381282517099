import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { LineBreak } from '../../../../Content';
// @ts-ignore
import { ImageContainer, Button, EventDesc, Header, Blurb, Wrapper } from '../../BaseHeroStyledComponents';
import { navigate } from 'gatsby';
import { ArrowLeft} from '../../../../Svg/arrows';
import styled, { css } from 'styled-components';

const BackDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Hero = () => (
  <Wrapper>
  <button onClick={() => navigate(-1)}><BackDiv>{ArrowLeft}<h3><u>Back</u></h3></BackDiv></button>
    <Blurb>
      <p>September 4, 2022</p>
      <Header>Orange Blossom Classic football game</Header>
      <EventDesc>
        Ready Life was the 2022 Presenting Sponsor for the Orange Blossom Classic featuring Jackson
        <LineBreak desktop /> State University and Florida A & M in Miami Gardens.
      </EventDesc>
      <Button url={'https://youtu.be/YLZLwOi4aoc'}>Watch video recap</Button>
    </Blurb>
    <ImageContainer>
      <StaticImage
        className={'main-image'}
        src={'../../../../../images/events/orange-logo.png'}
        alt={'Orange Blossom Classic football game logo'}
        placeholder={'none'}
      />
    </ImageContainer>
  </Wrapper>
);

export default Hero;
