import React from 'react';
import WebsiteDefaultPage from '../../../components/Page/Base';
import { Hero, CarouselOrange } from '../../../components/PageSpecific/community/events/orange-blossom';
import { JoinSection } from '../../../components/SharedSections';
import { Color } from '../../../styles';
import { LIGHT_DARK } from '../../../constants';
import { graphql } from 'gatsby';
import { createGlobalStyle } from 'styled-components';

const GlobalStyleOverride = createGlobalStyle`
   #body {
      min-height: unset;
    }
`;

const OrangeBlossom = ({ data: { eventImages } }) => (
  <>
    <GlobalStyleOverride />
    <WebsiteDefaultPage bodyBackground={Color.OFF_WHITE} pageState={{ theme: LIGHT_DARK.LIGHT }}>
      <Hero />
      <CarouselOrange assets={eventImages.edges} />
      <JoinSection />
    </WebsiteDefaultPage>
  </>
);

export const EventQuery = graphql`
  query {
    eventImages: allFile(
      filter: { relativeDirectory: { eq: "events" }, extension: { regex: "/(jpg)|(png)|(jpeg)/" } }
      sort: { fields: relativePath, order: ASC }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`;

export default OrangeBlossom;
