import React from 'react';
import styled from 'styled-components';
import { Button as BaseButton } from '../../Gui/Button';
import {
  Color,
  h1Desktop,
  h1Mobile,
  marginTop,
  marginBottom,
  maxWidth,
  maxHeight,
  paddingTop,
  typographyByBreakpoint,
  fullColorBackground,
  gridColumns,
  columnSpacing,
  columnStartEnd,
  widths,
  paddingTopBottom,
  height,
  flex,
} from '../../../styles';

const Wrapper = styled.section`
  ${paddingTopBottom([136, 40], [152, 80], [206, 120])};
  ${fullColorBackground(Color.OFF_WHITE)};
  display: grid;
  ${gridColumns(8, 12)};
  ${columnSpacing(8, 16, 24)};
  ${maxWidth('unset', '68.8rem', '144rem')};
  margin-inline: auto;
`;

const Blurb = styled.div`
  margin-inline: auto;
  ${widths('32.7rem', '39.5rem', '72.7rem')};
  ${columnStartEnd([1, 9], [1, 8], [1, 9])};
`;
const Header = styled.h1`
  margin-top: 1.6rem;
  ${typographyByBreakpoint(h1Mobile, h1Mobile, h1Desktop)};
`;

const EventDesc = styled.p`
  ${marginTop(32, 32, 40)};
  ${paddingTop(16, 16, 40)};
  border-top: 1px solid ${Color.BLACK};
`;

const Button = styled(BaseButton)`
  ${marginTop(32, 32, 40)};
`;

const ImageContainer = styled.div`
  ${marginTop(40, 26, -30)};
  margin-inline: auto;
  ${columnStartEnd([1, 9], [8, 13], [9, 13])};
  ${flex('center', 'center')};
  ${maxWidth('32.8rem', '27.7rem', '48.1rem')};
  ${height('28.8rem', '30.9rem', '43.3rem')};
  .main-image {
    ${widths('22.8rem', '24rem', '33.6rem')};
  }
`;

export { ImageContainer, Button, EventDesc, Header, Blurb, Wrapper };
