import React from 'react';
import styled, { css } from 'styled-components';
import {
  absolute,
  bodyDefault,
  Color,
  flex,
  fullColorBackground,
  height,
  marginTop,
  maxWidth,
  paddingTopBottom,
  Transition,
  Width,
  width,
  widths,
} from '../../../../../styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Carousel } from '../../../../Gui/Carousel';
import { CarouselConsumerProps } from '../../../../Gui/Carousel/Carousel';
import { Svg } from '../../../../Global/UI';
import { carouselData } from '../../../../../data/page/events/EventData';

const Wrapper = styled.section`
  ${fullColorBackground(Color.DARK_GRAY)};
  position: relative;

  .carousel-image {
    ${widths('32.7rem', '62rem')};
  }
`;

const SlideButton = styled.button<{ $active: boolean }>`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  border: 1px solid ${({ $active }) => ($active ? Color.BLACK : Color.PURPLE_GRAY)};
  background-color: ${({ $active }) => ($active ? Color.OFF_WHITE : 'none')};
`;

const SlideButtonsContainer = styled.div`
  ${flex('center', 'flex-start')};

  .slide-buttons * + * {
    margin-left: 1.2rem;
  }
`;

const CarouselFooter = styled.div`
  ${absolute('unset', 'unset', 0, 'unset')};
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  ${flex('flex-start', 'center', 'column')};
  ${height('40.5rem', '60rem')};
`;

const ButtonContainer = styled.div`
  z-index: 2;
  ${flex()};
  position: absolute;
  top: 9.3rem;

  > div {
    ${width('36.5rem', '72.5rem', '75.5rem')};
    ${flex('space-between')};

    button {
      ${flex()};
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: ${Color.GREEN};
    }
  }
  svg {
    height: 1.7rem;
    width: 1.2rem;

    path {
    }
  }

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    top: 19rem;
  }
`;

const ReviewContainerWrapper = styled.div`
  ${maxWidth('32.7rem', '62.8rem', '97.8rem')};
  ${paddingTopBottom([56, 56], [80, 80], [88, 104])};
  margin-inline: auto;
  ${flex('flex-start', 'flex-start', 'column')};
`;

const ReviewWrapperStyles = css<{ positionX: number; $visibility: boolean }>`
  transition: opacity ${Transition.DEFAULT}, transform ${Transition.DEFAULT}, visibility 0ms linear;
  transition-delay: ${({ positionX }) => (positionX === 0 ? '350ms, 350ms, 350ms' : '0ms, 0ms, 350ms')};
  opacity: ${({ positionX }) => (positionX === 0 ? 1 : 0)};
  visibility: ${({ $visibility }) => ($visibility ? 'visible' : 'hidden')};
  transform: ${({ positionX }) => {
    if (positionX < 0) {
      return 'translateX(-8px)';
    } else if (positionX > 0) {
      return 'translateX(8px)';
    }
    return 'translateX(0)';
  }};
`;

const BaseReviewWrapper = styled.div<{ positionX: number; $visibility: boolean }>`
  position: absolute;
  text-align: center;
  color: ${Color.OFF_WHITE};

  p {
    ${marginTop(24, 56)};
    ${bodyDefault};
  }
  ${ReviewWrapperStyles};
`;

const CarouselOrange = ({ assets }: { assets: any[] }) => {
  const findImageNode = imageData => {
    const imageName = imageData.replace('.', '').replace(/\s+/g, '-').toLowerCase();
    return assets.find(({ node }) => node.name === imageName).node.childImageSharp;
  };
  return (
    <Wrapper>
      <ReviewContainerWrapper>
        <Carousel data={carouselData} label={'carousel'} changeInterval={10000}>
          {({ slideContent, content, currentIndex, slidePositionX, changeSlide, changeSlideByIndex }: CarouselConsumerProps) => (
            <Slide>
              <ButtonContainer>
                <div>
                  <button onClick={() => changeSlide()} className={'slide-button-left-arrow'}>
                    <Svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 12L2 6.5L7 1" stroke={Color.BLACK} strokeWidth={2} />
                    </Svg>
                  </button>
                  <button onClick={() => changeSlide(true)} className={'slide-button-right-arrow'}>
                    <Svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 12L6 6.5L1 1" stroke={Color.BLACK} strokeWidth={2} />
                    </Svg>
                  </button>
                </div>
              </ButtonContainer>
              {slideContent.map(({ description, title, ariaSlideLabel, imageData }, index) => {
                return (
                  <BaseReviewWrapper
                    key={`Review-${title}-${index}`}
                    positionX={slidePositionX[index]}
                    $visibility={slidePositionX[index] === 0}
                    aria-hidden={slidePositionX[index] !== 0}>
                    <GatsbyImage className={'carousel-image'} alt={title} image={getImage(findImageNode(imageData))} />
                    <p>{description}</p>
                  </BaseReviewWrapper>
                );
              })}
              <CarouselFooter>
                <SlideButtonsContainer>
                  <div className="slide-buttons">
                    {carouselData.map((slide, index) => (
                      <SlideButton
                        key={`SlideButton-${index}`}
                        $active={content?.[currentIndex]?.realIndex === index}
                        onClick={() => changeSlideByIndex(index)}
                      />
                    ))}
                  </div>
                </SlideButtonsContainer>
              </CarouselFooter>
            </Slide>
          )}
        </Carousel>
      </ReviewContainerWrapper>
    </Wrapper>
  );
};

export default CarouselOrange;
